<template>
    <!-- <a-modal class="widget-styles-color" :title="null" :footer="null" :closable="false" :bodyStyle="{padding:0}" :visible="data.show" > -->
    <a-popover trigger="click" v-model="show">

        <div style="display: flex" slot="content">

            <div style="display: flex; align-items: center">
                <div :style="{ background: typeof color === 'string' ? color : color.hex, width: '10px'}"/>

                <Material v-model="color" class="custom-slider" style="height: auto" />
            </div>

            <div style="padding: 30px">
                <Slider v-model="color" style="max-width: 290px; margin-top: 5px" />

                <div role="group" class="vc-slider-swatches" style="margin-top: 10px">
                    <div v-if="none"
                        @click="setColor('')"
                        aria-label="color:#FFFF00"
                        role="button"
                        class="vc-slider-swatch">
                        <div class="vc-slider-swatch-picker" :class="isActive('#FFFFFF') ? 'vc-slider-swatch-picker--active' : ''" style="box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3); position:relative;">
                            <img src="https://bildhive.nyc3.cdn.digitaloceanspaces.com/assets/red-across.png" style="width:100%;height:100%; position:absolute; left:0;top:0;"/>
                        </div>
                    </div>
                    <div
                        @click="setColor('#FFFFFF')"
                        aria-label="color:#FFFF00"
                        role="button"
                        class="vc-slider-swatch">
                        <div class="vc-slider-swatch-picker" :class="isActive('#FFFFFF') ? 'vc-slider-swatch-picker--active' : ''"
                        style="background: #ffffff; box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);">
                        </div>
                    </div>
                    <div
                        @click="setColor('#DDDDDD')"
                        aria-label="color:#FFFF00"
                        role="button"
                        class="vc-slider-swatch"
                        >
                        <div
                        class="vc-slider-swatch-picker"
                        :class="
                            isActive('#DDDDDD') ? 'vc-slider-swatch-picker--active' : ''
                        "
                        style="
                            background: #dddddd;
                            box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                        "
                        ></div>
                    </div>
                    <div
                        @click="setColor('#AAAAAA')"
                        aria-label="color:#FFFF00"
                        role="button"
                        class="vc-slider-swatch"
                    >
                        <div
                        class="vc-slider-swatch-picker"
                        :class="
                            isActive('#AAAAAA') ? 'vc-slider-swatch-picker--active' : ''
                        "
                        style="
                            background: #aaaaaa;
                            box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                        "
                        ></div>
                    </div>
                    <div
                        @click="setColor('#666666')"
                        aria-label="color:#FFFF00"
                        role="button"
                        class="vc-slider-swatch"
                    >
                        <div
                        class="vc-slider-swatch-picker"
                        :class="
                            isActive('#666666') ? 'vc-slider-swatch-picker--active' : ''
                        "
                        style="
                            background: #666666;
                            box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                        "
                        ></div>
                    </div>
                    <div
                        @click="setColor('#000000')"
                        aria-label="color:#FFFF00"
                        role="button"
                        class="vc-slider-swatch"
                    >
                        <div
                        class="vc-slider-swatch-picker"
                        :class="
                            isActive('#000000') ? 'vc-slider-swatch-picker--active' : ''
                        "
                        style="
                            background: #000000;
                            box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                        "
                        ></div>
                    </div>

                </div>

                <template v-if="alpha">
                    <a-divider>Opacity</a-divider>
                    <a-slider v-model="alphaValue" :min="0" :max="1" :step="0.01" />
                </template>

				<template v-if="transparent">
                    <a-divider>Opacity</a-divider>
                    <a-slider v-model="alphaValue" :min="0" :max="1" :step="0.01" />
                </template>

                <a-divider>{{recentText? recentText : `Theme Colors`}}</a-divider>

                <!-- <Compact v-model="color" /> -->

                <div role="group" class="vc-slider-swatches" style="margin-top: 10px">
                <div
                    v-for="(clr, clrI) in recentColors"
                    :key="`recentColor${clr}${clrI}`"
                    @click="setColor(clr)"
                    :aria-label="`color:${clr}`"
                    role="button"
                    class="vc-slider-swatch"
                >
                    <div
                    class="vc-slider-swatch-picker"
                    :class="isActive(clr) ? 'vc-slider-swatch-picker--active' : ''"
                    :style="`background:${clr} ; box-shadow:0 2px 5px rgba(100,100,100,0.3)`"
                    ></div>
                </div>
                </div>
            </div>
        </div>

        <div :class="{'box-style-color-picker':boxStyle}">

            <a-avatar
                v-if="color"
                :style="{
                    borderRadius:boxStyle? '0' : '50%',
                    background: typeof color === 'string' ? color : color.hex,
                    border: '1px solid #eee',
                    boxShadow: '1px 2px 5px rgba(200,200,200,0.5)',
                }" class="bordered-avatar" />
            <a-avatar
                v-else
                src="https://bildhive.nyc3.cdn.digitaloceanspaces.com/assets/red-across.png"
                :style="{
                    borderRadius:boxStyle? '0' : '50%',
                    background: typeof color === 'string' ? color : color.hex,
                    border: '1px solid #eee',
                    boxShadow: '1px 2px 5px rgba(200,200,200,0.5)',
                }" class="bordered-avatar" />

            <span class="uppercase" style="text-transform:uppercase;" v-if="boxStyle">
                {{typeof color === 'string' ? color : color.hex ? color.hex : ''}}
            </span>

        </div>

    </a-popover>

  <!-- </a-modal> -->
</template>

<script>
import { Slider, Material, Compact } from "vue-color";
function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}
function hexToRgbaObj(hex) {
	console.log('HEX TO RGBA OBJ', hex)
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return {
        r,g,b,a:1
    }
}

function RGBToHex(r,g,b) {
    r = +r
    g = +g
    b = +b

    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length == 1)
    r = "0" + r;
    if (g.length == 1)
    g = "0" + g;
    if (b.length == 1)
    b = "0" + b;

    return "#" + r + g + b;
}

export default {
    components: { Slider, Material, Compact },
    props: ["value", "box-style",'defaults','recent-text','none','alpha','colors','transparent'],
    data() {
        return {
            color: this.value,
            show: false,
            rcnt: ["#FFFFFF", "#DDDDDD", "#000000"],
            alphaValue:1.0,
            init:false,
        }
    },
    watch: {
        alphaValue(){
            this.emit()
        },
        color(val) {
            this.emit()
        },
        show(val) {
            // if (val) this.color = {hex:this.value}
            if (val) {
                let value = this.value
                if (value.includes('rgb')){
                    let clr = value.split(')')[0].split('(')[1]
                    let [r,g,b,a =1 ] = clr.split(',')
                    this.alphaValue = +a
                    console.log('RGBAAAAAA',r,g,b)
                    value = RGBToHex(r,g,b)
                    console.log('VALUEEEEEE INSIDEEEEEEEEE', value)

                } else{
                    this.alphaValue = 1
                }
                console.log('VALUEE SO FAR', value)
                if (value && value.includes('#')) this.color = {hex:value,rgba:hexToRgbaObj(value)}
                else this.color = ''

            }
            setTimeout(() => {
                this.init = true
            }, 10);
        },
    },
    computed: {
        recentColors() {
            let clrs = this.$store.state.appData.cson.colors || [];
            clrs = clrs.filter((x, xI) => xI < 10);
            let defaults = this.rcnt
            let colors = []
            if (this.colors){
                let {primaryColor, secondaryColor, textColor, accentColor} = this.colors
                colors = [primaryColor, secondaryColor, textColor, accentColor]
            }
            if (colors && colors.length) {
                defaults = colors.filter(x => x !== undefined)
            }
            if (clrs.length <= 7) clrs = [...clrs, ...defaults];
            return clrs;
        },
    },
    methods: {
        emit(){


            let val = this.color
            let result = ''

            if (val){
                if (this.alpha && this.alphaValue){
                    if (!val.rgba) {
                        val.rgba = hexToRgbaObj(val.hex)
                    }

                    let rgba = val.rgba
                    result = `rgba(${rgba.r},${rgba.g},${rgba.b},${this.alphaValue})`
                } else if (this.transparent){
					if (!val.rgba) {
                        val.rgba = hexToRgbaObj(val.hex)
                    }

                    let rgba = val.rgba
                    result = `rgba(${rgba.r},${rgba.g},${rgba.b},${this.alphaValue})`
				} else if (val.hex) result = this.color && this.color.hex
            }
            if (!this.init) return
            this.$emit("input", result);
        },
        setColor(color) {
			console.log('Setting color...', color)
            if (!color) return this.color = ''
            this.color = { hex: color };
        },
        isActive(clr) {
            if (clr === '' && !this.color) return true
            let color = this.color;
            if (typeof color !== "string") color = color.hex;

            color = color.toLowerCase().trim();
            clr = clr.toLowerCase().trim();

            return clr === color;
        },
        okColor() {
            let data = this.data;
            if (data && typeof data.callback === "function") {
            data.callback(this.color);
            } else {
            // return this.$emit("ok", this.color);
            }
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
    .box-style-color-picker{
        width:94px;
        border:1px solid #ccc;
        display:inline-flex;
        align-items:center;
        padding:2px 5px;
        gap:1ch;
        .ant-avatar{
            width:13px;
            height:13px;
        }
    }
</style>
